<template>
  <div class="container mt-5">  
    <b-card :img-src="require('../assets/logo.jpeg')" img-alt="Card image" img-top header-tag="header" header-bg-variant="secondary" header-text-variant="white" header-class="header">
      <template #header>
          <h4 class="mb-0">Ouverture de compte</h4>
       </template>
      <b-card-body>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(onSubmit)" >
          <text-input v-model="code" label="Numéro de compte client" type="text" rules="required"></text-input>
          <text-input v-model="email" label="Email" type="email" rules="required"></text-input>
          <text-input v-model="password" label="Mot de passe" type="password" rules="required|min:8" ref="password"></text-input>
          <!-- <text-input label="Répetez le mot de passe" type="password" rules="required|confirmed:password"></text-input> -->
          <div class="d-flex justify-content-end">

            <b-button type="submit" variant="primary">Enregistrer<b-spinner v-if="loading" small type="border"></b-spinner></b-button>
          </div>
        </b-form> 
        </validation-observer>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>

//import DossierService from '../services/DossierService'
import TextInput from '../components/TextInput.vue'

  export default {
    name:"Signup",
    components: { TextInput },
    data() {
      return {
        email : null,
        password : null,
        code : null,
        loading: false,
        message:""
      }
    },
    computed : {

    },
    methods : {
      onSubmit() {

        let user = {
            code_app : "APP_DOSSIER",
            numTransaction : Math.floor(Math.random() * 100000) + 1,
            code_organisation : this.code,
            email : this.email,
            pswd : this.password
        }

        this.loading = true;
        this.$store.dispatch('create', user).then(
            () => {
                this.loading = false;
                this.$bvModal.msgBoxOk('Enregitré avec succés', {
                  title: 'Confirmation',
                  okVariant: 'success',
                  headerClass: 'p-2 border-bottom-0 bg-secondary text-white',
                  footerClass: 'p-2 border-top-0',
                  centered: true
                }).then(value => {
                  console.log(value)
                  if(value) {
                    this.$router.replace('/')
                  }
            })
            },
            error => {
                this.loading = false;
                if(error.response.status == 403) {
                  this.message = 'Login ou mot de passe incorrect'
                }
                else {
                  this.message = error.response.data.message_specifique
                }

                this.$bvModal.msgBoxOk(`Erreur lors de la création du compte : ${this.message}`, {
                  title: 'Erreur',
                  okVariant: 'danger',
                  headerClass: 'p-2 border-bottom-0 bg-danger text-white',
                  footerClass: 'p-2 border-top-0',
                  centered: true
                })
            }
        )       
      }
    },
    mounted() {

    }
  }
</script>


<style scoped>


.card-img-top {
  margin: 15px 0px 15px 0px;
}
</style>