<template>
  <div id="app">
    <div>
  <b-navbar v-if="isLoggedIn" toggleable="lg" type="dark" variant="primary">
    <b-navbar-brand href="#">
      VD2E
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item to="/dossiers">Dossiers</b-nav-item>
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown right>
          <template v-slot:button-content>
            <em>{{user.login}}</em>
          </template>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item href="#" @click="logout()">Déconnexion</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</div>
    <router-view/>
    <div class='footer'>
      Propulsé par Oleweb.fr
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  computed: {
    isLoggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    user() {
      return this.$store.state.auth.user
    }
  },
  methods : {
    logout() {
      this.$store.dispatch('logout');
      this.$router.push('/');
    }
  }
}
</script>

<style>


#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  /* margin-top: 60px; */
}

.card-body {
  padding: 0.25rem !important;
}

.list-group-item {
    padding: 0.75rem 0.25rem !important;
}

.footer {
  align-items: center;
  height: 30px;
  text-align: center;
  vertical-align: center;
  background-color: #61cf52;
}

</style>
